/* You can add global styles to this file, and also import other style files */

html, body {
  min-height: 100%;
}

body {
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
}

@import 'front/variable';
@import 'front/theme';
@import 'front/fonts';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';

/* MODAL */
.cdk-overlay-backdrop {
  background: rgba(34, 28, 70, 0.65) !important;

  &.cdk-overlay-backdrop-showing {
    opacity: 1 !important;
    backdrop-filter: blur(6px);
    //background: rgba(5, 46, 57, 0.85) !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #004691 !important;
}

.cdk-drop-list.list {
  border: 1px solid #004691 !important;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left !important;
}

.box-divider {
  width: 100%;
  margin: 30px auto 20px;
}

.datepicker-field {
  .mat-form-field-wrapper {
    height: 80px;

    .mat-datepicker-input,
    .mat-datepicker-toggle {
      top: -11px;
      position: relative;
    }
  }
}

@media screen and (max-width: 1318px) {
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    white-space: nowrap; //no break line
    padding: 0 10px !important;
  }

  .table-coverage {
    th.mat-header-cell {
      padding: 0 !important;
    }
  }
}

.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-sm {
  @media (max-width: 959px) {
    flex-direction: column !important;
  }
}

.flex-column-gt-sm {
  @media (max-width: 1368px) {
    flex-direction: column !important;
  }
}

.flex-column-gt-md {
  @media (max-width: 1465px) {
    flex-direction: column !important;
  }
}

.gap-xs-none {
  @media (max-width: 599px) {
    gap: 0;
  }
}

.mw-55 {
  max-width: 55%;
}


.full-width-table {
  width: 100%;
}

button.mat-raised-button {
  span.mat-ripple {
    background: none !important;
  }
}


.btn-lg {
  height: 70%;
  width: 170px;
  color: #004691 !important;
  border-radius: 10px !important;
  @media (max-width: 1444px) {
    height: 50px;
  }
}

.color-primary {
  color: $primary !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-none {
  border-radius: 0 !important;
}

.mat-title {
  &.faq {
    font-size: 26px;

    strong {
      font-size: 29px;
    }
  }

  &.big {
    font-size: 26px;
  }
}

.justify-center {
  justify-content: center !important;
}


.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: end;
}

.justify-start {
  justify-content: start;
}

.cursor-pointer {
  cursor: pointer;
}

.background-white {
  background-color: #fff;
}

.color-white {
  color: #fff !important;
}

.justify-between {
  justify-content: space-between !important;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
}

.transform-icon {
  transform: scale(0.8);
}


.transform-icon-like {
  transform: scale(0.6);
}

//.w-100 {
//  width: 100% !important;
//}
//
//.h-100 {
//  height: 100% !important;
//}
//
//.w-100-xs {
//  @media (max-width: 599px) {
//    width: 100% !important;
//  }
//}
//
//.w-100-sm {
//  @media (max-width: 959px) {
//    width: 100% !important;
//  }
//}
//
//.w-100-md {
//  @media (max-width: 1280px) {
//    width: 100% !important;
//  }
//}
//
//.w-50-md {
//  @media (max-width: 1280px) {
//    width: 50%;
//  }
//}
//
//.w-100-gt-sm {
//  @media (max-width: 1368px) {
//    width: 100%;
//  }
//}
//
//.w-100-lg {
//  @media (max-width: 1550px) {
//    width: 100%;
//  }
//}

//.inner-card {
//  overflow: auto;
//  //max-height: 550px;
//}


.max-w-md {
  @media (max-width: 1280px) {
    max-width: 100% !important;
  }
}

.max-w-lg {
  @media (max-width: 1550px) {
    max-width: 100% !important;
  }
}

.gap-10-md {
  @media (max-width: 1280px) {
    gap: 10px;
  }
}

.gap-15-md {
  @media (max-width: 1280px) {
    gap: 15px;
  }
}

.gap-15-sm {
  @media (max-width: 959px) {
    gap: 15px;
  }
}

.gap-30-md {
  @media (max-width: 1280px) {
    gap: 30px;
  }
}

.gap-30-sm {
  @media (max-width: 959px) {
    gap: 30px;
  }
}

.gap-30-lg {
  @media (max-width: 1550px) {
    gap: 30px;
  }
}

.inner-side {
  padding-left: 40px !important;
}


.tab-custom {
  .mat-ripple.mat-tab-label.mat-focus-indicator {
    background-color: #004691 !important;
    color: #EB0028 !important;
    width: 50%;
    height: 50px;
    font-size: 16px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    opacity: 1;

    &.mat-tab-label-active {
      background-color: #EB0028 !important;
      color: #004691 !important;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}

.mat-tab-label-active {
  height: 60px !important;
  font-size: 18px;
  color: #052E39;
  font-weight: bold;
}

.mat-tab-labels {
  align-items: end;
  justify-content: space-around;
}


.mat-raised-button {
  line-height: 37px !important;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  border: solid 2px $primary;
  background-color: white !important;
  color: $primary !important;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  border: solid 2px $primary;
  background-color: $tertiary !important;
  color: $primary !important;
}

.flex-wrap {
  flex-wrap: wrap;
  display: flex;
}


.show-divider-sm {
  display: none;
  @media (max-width: 959px) {
    display: block !important;
  }
}

.break-all {
  word-break: break-all;
}

.datepicker-extract {
  .mat-ripple {
    background: none !important;
  }
}

.paginator-nocolor {
  .mat-ripple {
    background: none !important;
  }
}

/*.mat-list-item.selected > .mat-list-item-content > a {
  border-radius: 0 !important;
  background-color: #EB0028 !important;
  font-weight: bold !important;

  span {
    color: #ffffff !important;
  }

  svg-icon {
    svg {
      fill: #ffffff !important;
      stroke: #ffffff !important;
    }
  }
}*/

.mat-list-base .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}

.text-size-small {
  font-size: 12px;
}

.text-size-regular {
  font-size: 16px;
}

.text-size-middle {
  font-size: 22px;
}

.text-size-large {
  font-size: 28px;
}

.text-size-big {
  font-size: 36px;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-tertiary {
  color: $tertiary;
}

.btn {
  display: flex;
  cursor: pointer;
  font-size: 15px;
  border: 1px solid;
  padding: 10px 35px;
  align-items: center;
  justify-content: center;

  &-block {
    width: 100%;
    display: block;
  }

  &-outline {
    &-primary {
      color: $primary;
      border-color: $primary;
      background-color: transparent;
    }

    &-secondary {
      color: $secondary;
      border-color: $secondary;
      background-color: transparent;
    }

    &-white {
      color: $white;
      border-color: $white;
      background-color: transparent;
    }
  }

  &-primary {
    color: $white;
    border-color: $primary;
    background-color: $primary;
  }
}

.delete-btn {
  display: flex;
  height: 40px;
  width: 140px;
  max-width: 80px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #940101;

  svg-icon {
    fill: #940101;

    svg {
      width: 20px;
    }
  }
}

.add-btn {
  svg-icon {
    margin-right: 5px;
  }

  cursor: pointer;
  display: flex;
  height: 40px;
  width: 140px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #004691;

  span {
    font-size: 14px;
    color: #004691;
  }
}


.option {
  background-color: #EFEFEF;
  padding: 11px 16px;

  &.offers {
    padding: 11px 16px 11px 0;
  }

  border-radius: 5px;
  margin: 6px 0;
}

.add-button {
  background-color: #004691;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  height: 36px;
  width: 36px;

  mat-icon {
    margin-left: 1px;
  }
}

.delete {
  background-color: #EB0028;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.eye {
  background-color: #FAB932;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.view {
  background-color: #7FA2C8;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.duplicate {
  background-color: #7FA2C8;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;
}

.edit {
  background-color: #004691;
  padding: 8px 10px;
  border-radius: 5px;
  margin: 6px 0;

  svg-icon {
    svg {
      fill: #ffffff !important;
    }
  }
}

.edit-circle {
  svg-icon {
    svg {
      fill: #004691 !important;
    }

  }

  background-color: #FAB932;
  padding: 1px 7px;
  border-radius: 100%;

}

.delete-circle {
  background-color: #EB0028;
  padding: 1px 7px;
  border-radius: 100%;
}

.duplicate-circle {
  background-color: #DFDFDF;
  padding: 1px 7px;
  border-radius: 100%;
}

.option-circle {
  background-color: #DFDFDF;
  padding: 1px 7px;
  border-radius: 100%;
}

.svg-back {
  svg {
    transform: rotate(90deg);
  }
}

.svg-white {
  svg {
    // stroke: #ffffff !important;
    fill: #FFFFFF !important;
  }
}

.svg-red {
  svg {
    fill: #EB0728 !important;
  }
}

.svg-primary {
  svg {
    fill: $primary !important;
  }
}

.svg-secondary {
  svg {
    fill: $secondary !important;
  }
}

.svg-tertiary {
  svg {
    fill: $tertiary !important;
  }
}

.dialog-card {
  width: 100%;
  padding: 5px;
  border-radius: 15px;
  background-color: #EFF3F8;
}

.cdk-overlay-pane {
  //width: 66vw !important;
  //border: solid 2px $primary;
  overflow: auto;
  @media (max-width: 600px) {
    //width: 95vw !important;
  }
}


.dialog-subtitle {
  color: #848484;
  font-weight: bold;
}

.logo-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  min-width: 300px;
  height: 300px;
  background-color: #ffffff;
  align-items: center !important;
  justify-content: center !important;
}

.logo-card-small {
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;
  width: 110px;
  height: 60px;
  background-color: #ffffff;

  &.user-highlight {
    width: 70px;
    height: 70px;
    margin-bottom: 0;
  }

  .img-card {
    height: 100% !important;
  }

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.logo-card-medium {
  padding: 5px;
  border-radius: 5px;
  width: 200px;
  height: 60px;
  background-color: #ffffff;

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
  }
}

.logo-card-big {
  padding: 5px;
  border-radius: 5px;
  width: 305px;
  height: 165px;
  background-color: #ffffff;
  margin-bottom: 30px;
  justify-content: center;

  &.highlight {
    width: 200px !important;
    min-height: 300px !important;
  }

  span {
    font-size: 10px;
  }

  @media(max-width: 600px) {
    margin-bottom: 20px;
  }
}


.img-card {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #DFDFDF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.user-highlight {
    height: 100%;
    width: 100%;
  }

  &.highlight-content {
    height: 90%;
  }

  span {
    color: #848484;
    font-weight: bold;
  }
}

.cancel-button {
  width: 120px;
  height: 45px;
  background-color: transparent !important;
  border: 2px solid #004691 !important;
  color: #004691 !important;
}

.save-button {
  width: 120px;
  height: 60px;
  background-color: #004691 !important;
  border: 2px solid #004691 !important;
  color: #ffffff !important;
}


.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  //color: #ffffff !important;
  border-radius: 10px !important;

}

.mat-form-field-appearance-outline .mat-form-field-outline {
  //color: #ffffff !important;
  border-radius: 10px !important;
}

.divider-row {
  width: 100%;
  height: 1px;
  background-color: #004691;
  margin-top: 10px;
  margin-bottom: 20px;

  &.white {
    background-color: #fff !important;
  }
}

.mat-elevation-z8 {
  box-shadow: none !important;
  overflow-x: auto;
  width: 100%;
}

table.mat-table {
  border-collapse: initial;
  border-spacing: 0;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

mat-paginator {
  border-radius: 0 0 15px 15px;
}

#snav .mat-list-item a {
  margin-bottom: 0 !important;
}

.mat-divider {
  border-top-color: $primary !important;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.th-no-padding {
  padding: 0;
  @media (max-width: 1320px) {
    padding: 0 !important;
  }
}


th.mat-header-cell {
  white-space: nowrap;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}

.align-items-start {
  align-items: start;
}

.tab-card {
  &-top {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: end;

    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
      border-radius: 20px 20px 0 0;
      background-color: #EAEAEA;
      width: 50%;
      height: 50px;

      span {
        color: #014691;
      }

      &.active {
        height: 70px;
        padding: 20px;

        span {
          font-weight: bold;
          color: #ffffff;
        }

        svg-icon {
          svg {
            fill: #FAB932 !important;
            // stroke: #FAB932 !important;
          }
        }

        background-color: #014691;
      }
    }
  }

  &-content {
    background-color: #ffffff;
    //padding: 20px 20px 0 20px;
  }
}

.treat-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.is-cursor {
  cursor: pointer;
}

.table-hide-button {
  &-item {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .hover-buttons:hover {
    .table-hide-button-item {
      opacity: 1;
      visibility: visible;
    }
  }
}

.high-hide-button {
  &-item {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  .hover-buttons:hover {
    .high-hide-button-item {
      opacity: 1;
      visibility: visible;
    }
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.campaign-inner {
  &.dark {
    background-color: #DFDFDF;
  }

  padding-right: 100px;
  padding-left: 100px;
  @media(max-width: 800px) {
    padding: 0
  }
}


.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;

    &-service {
      margin: 1px 5px 0;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.circle-radius {
  border-radius: 50%;
}

.high-hide-button {
  .edit,
  .delete,
  .duplicate-move,
  .option,
  .add-btn,
  .view,
  .eye {
    transition: transform 0.5s ease;
  }

  .add-btn {
    transform: translateX(85px);
  }

  &:hover {
    .eye {
      transform: translateX(-20px);
    }

    .edit {
      transform: translateX(-15px);
    }

    .duplicate-move {
      transform: translateX(-15px);
    }

    .delete {
      transform: translateX(-10px);
    }

    .add-btn {
      transform: translateX(-20px);
    }

    .view {
      transform: translateX(-20px);
    }
  }
}

.outline-button {
  background-color: transparent !important;
  border: 2px solid #004691 !important;
  color: #004691 !important;
}

.overlay-scroll {
  height: 50vh !important;
  overflow: auto !important;
}

.overlay-scroll-max {
  max-height: 50vh !important;
  overflow: auto !important;
}

.disabled-click {
  pointer-events: none;

  .delete {
    background-color: #EFEFEF;
  }
}

.bg {

  &-white {
    background-color: #FFFFFF !important;
  }

  &-black {
    background-color: #080808 !important;
  }

  &-gray {
    background-color: #EFF3F8 !important;
  }
}


.flex-grow-1 {
  flex-grow: 1;
}

.w-50 {
  width: 50%;
}

.radius-bottom-right-10 {
  border-radius: 0 0 10px 0 !important;
}

.radius-bottom-left-10 {
  border-radius: 0 0 0 10px !important;
}

.radius-top-right-10 {
  border-radius: 0 10px 0 0 !important;
}

.radius-top-left-10 {
  border-radius: 10px 0 0 0 !important;
}

.radius-top-10 {
  border-radius: 10px 10px 0 0 !important;
}

.radius-bottom-10 {
  border-radius: 0 0 10px 10px !important;
}

.text-align-end {
  text-align: end;
}

.filter-gray-bg {
  filter: contrast(75%) brightness(115%);
}

.dark-mode-bg {
  background-color: #030722;
}

.z-index-1 {
  z-index: 1;
}

.focus-offer {
  display: flex;
  border-radius: 5px;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  height: 52px;
  align-items: center;

  span {
    font-weight: bold;
    color: #014691;
  }

  @media(max-width: 700px) {
    margin-bottom: 10px;
  }
}

.mat-tab-body-wrapper {
  .mat-tab-body-content {
    padding-top: 30px;
    overflow-x: hidden;
  }
}

button:disabled,
[disabled] {
  opacity: 0.5;
}

.slick-prev:before, .slick-next:before {
  color: $secondary !important;
}

.slick-prev, .slick-next {
  top: 30% !important;
}

/*.slick-dots{
  li{
    width: 40px !important;
    button{
      width: 40px !important;
      &:before{
        width: 40px !important;
      }
    }
  }
}*/

/* Ocultar os dots padrão */
.slick-dots li button:before {
  display: none;
}

.slick-dots {
  display: flex;
  justify-content: center;
}

.slick-dots li {
  width: 12px !important;
  height: 12px !important;
  margin: 0 10px !important;
  //animation: margin 0.2s ease;
}

.slick-dots li.slick-active {
  .slick-dot-bar {
    width: 100%;
    background-color: $primary;
    //animation: progressSlickDots 0.2s linear;
  }
}

.dots-primary .slick-dots li.slick-active .slick-dot-bar {
  background-color: $primary;
}

.slick-dots li .slick-dot-bar {
  border: 2px solid $primary;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  animation: width 0.2s ease;
}

@keyframes progressSlickDots {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


.slick-slide {
  margin: 0 27px;
}

.slick-list {
  margin: 0 -27px;
}

.slick-track {
  margin-left: 0 !important;
}

.offers {
  .offers-top > div {
    @media (max-width: 1465px) {
      gap: 12px;
      width: 100% !important;
      align-items: center !important;
      justify-content: center;
    }
  }

  .manage {
    justify-content: center;
  }
}

.filter-option {
  &[disabled] {
    opacity: 1 !important;
  }

  .mat-pseudo-checkbox {
    opacity: 0 !important;
  }
}


.list-thumbs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  &-item {
    width: 25%;
    text-align: center;
  }

  &-label {
    width: 100%;
    height: 30px;
    padding: 0 1px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sc-vrqbdz-8.eLaznq {
  display: none;
}

.hero {

  .slick-dots {
    bottom: 10px;
    max-width: 1300px;
    text-align: right;

    li .slick-dot-bar {
      background-color: $white;
    }

    .slick-active {
      .slick-dot-bar {
        background-color: $tertiary;
      }
    }
  }
}

.wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;

}

.card {
  padding: 20px;
  //border-radius: 14px;
  background-color: $white;
  border: solid 2px $primary;

  &.dark {
    color: $white;
    background-color: rgba(5, 46, 57, 0.7);

    &.full-fill {
      background-color: $primary;
    }
  }

  &-header {
    margin-bottom: 0px;

    &-title {
      color: $primary;
      font-size: 22px;
      font-weight: bold;
      margin: 0 0 0 0;
    }

    &-subtitle {
      color: $primary;
      font-size: 16px;
      margin: -8px 0 0 0;
    }
  }

  &-body {
    color: $primary;

    dt {
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }

  &-thumbnail {
    padding: 0;
    min-height: 100%;

    &-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &-box {
      padding: 0 20px 5px;
    }
  }

  &-panel {
    width: 90%;
    max-width: 650px;
    margin: 20px auto;

    @media(max-width: 1080px) {
      width: 100%;
      padding: 0 10px;
    }

    &-item {
      border-top: solid #221D46 2px;
      border-right: solid #221D46 2px;
      border-left: solid #221D46 2px;
      font-size: 14px;
      font-weight: normal;
      background-color: #ffffff;
      color: #221D46;
      margin: 20px;

      &.comment {
        margin: 35px 0 0;
      }

      &.fixed {
        margin: 35px 0 0;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 20px;

      &.no-unit {
        display: flex;
        align-items: center;
        padding: 10px 20px 20px;
      }

      &-options {
        display: flex;
        align-items: end;
        justify-content: end;
      }

      &-thumb {
        margin: 0 7px 0 0;

        img {
          width: 40px;
          height: 40px;
          display: block;
          object-fit: cover;
          border-radius: 40px;
          border: 2px solid #00EB5E;
        }

        &-comment {
          width: 100%;
          height: 100%;
        }

        &.modal {
          border: none;
        }
      }


      &-info {
        margin-left: 7px;
        flex-grow: 1;

        &-title {
          font-size: 12px;
          color: $primary;
          font-weight: 500;
          line-height: 20px;

          &.no-unit {
            justify-content: center !important;
          }
        }

        &-label {
          font-size: 12px;
        }
      }
    }

    &-body {
      font-size: 14px;

      &-text {
        word-break: break-all;
        display: block;
        padding: 0 20px 20px;
      }

    }

    &-footer {
      display: flex;
      padding: 13px 20px 20px;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.like-group,
.comment-group {
  display: flex;
  font-size: 13px;
  align-items: center;

  svg-icon {
    display: flex;
  }

  svg {
    margin: 0 7px 0 0;
  }
}

.panel-expanded {
  max-width: 380px;
  min-width: 300px;
  width: 40%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transform: translate(-100%, 0);

  &.right {
    border-radius: 10px 0 0 10px;
    transform: translate(100%, 0);
    left: initial;
    right: 0;
  }

  @media(max-width: 780px) {
    min-width: 100%;
    overflow-y: auto;
  }

  &.show {
    transform: translate(0, 0);
  }

  &-header {
    background-color: $white;
    border-bottom: 2px solid $primary;
    padding: 20px;

    &-title {
      font-weight: bold;
      font-size: 28px;
      color: $primary;
      margin: 0;
    }
  }

  &-body {
    overflow: auto;
    height: 80%;
  }

  &-footer {
    display: flex;
    padding: 20px;
    margin: 5px 0 0 0;
    align-items: center;
    border-top: 1px solid $primary;
    justify-content: space-between;

    &-link {
      color: $primary;
      font-size: 13px;
      font-weight: bold;
    }
  }
}

.breadcumbs {
  width: 100%;
  display: flex;
  padding: 10px 5px;
  max-width: 1280px;
  align-content: center;
  margin: 20px auto 40px;
  border-bottom: 1px solid $white;

  @media (max-width: 1440px) {
    max-width: 1080px;
  }

  @media (max-width: 1080px) {
    width: 96%;
  }

  &-item {
    color: $white;
    display: flex;
    margin: 0 15px 0 0;
    padding: 0 15px 0 0;
    align-content: center;
    border-right: 1px solid $white;

    &:last-child {
      border-right: none;
    }
  }

  &-ico {
    display: flex;
    align-items: center;
    margin: 0 5px 0 0;

    svg {
      fill: $white;
    }
  }

  &-label {
    color: $white;
    display: flex;
    align-items: center;

    @media (max-width: 1080px) {
      font-size: 12px;
    }
  }
}

.campaign {
  &-grid {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1080px) {
      flex-direction: column;
    }

    .kingdom-beasts {
      //width: 100%;
      max-width: 400px;
      text-align: center;

      @media (max-width: 1080px) {
        max-width: 100%;
      }

      .card-kingdom {
        min-height: auto;
      }

      .kingdom-info {
        padding: 10px 40px;
        text-align: left;

        &.graphic {
          padding: 0;
        }
      }
    }
  }

  &-last {
    margin: 10px;
    flex-grow: 1;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    position: relative;

    @media (max-width: 1080px) {
      min-height: 400px;
    }

    &-image {
    }

    &-container {
      top: 5%;
      left: 3%;
      width: 94%;
      height: 90%;
      position: absolute;
      display: flex;
      border: 4px solid $white;
      border-radius: 10px;

      &-info {
        color: $white;
        margin: 40px;

        &-label {
          font-size: 22px;
          margin: 0 0 10px 0;
        }

        &-title {
          font-size: 45px;
          margin: 0 0 0 0;
        }
      }
    }

    &-row {
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: space-between;

      @media (max-width: 1080px) {
        justify-content: end;
        flex-direction: column;
      }
    }

    &-col {
    }

    &-btn {
      margin: 40px;
      font-size: 16px;
      color: $primary;
      cursor: pointer;
      padding: 10px 25px;
      font-weight: bold;
      border: 2px solid $white;
      background-color: rgba(255, 255, 255, 0.6);

      @media (max-width: 1080px) {
        margin: 0 40px 40px;
      }
    }
  }

  &-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;

    &-item {
      width: calc(33.333% - 20px);
      margin-bottom: 20px;

      @media (max-width: 1080px) {
        width: calc(50% - 20px);
      }

      @media (max-width: 780px) {
        width: 100%;
      }
    }
  }
}

.text-regulation {
  font-size: 14px;
  //margin-top: 40px;

  &-title {
    color: $primary;
    font-size: 20px;
    text-align: center;
    margin: 0 0 20px;
  }

  h3 {
    font-size: 16px;
    margin: 25px 0 10px 0;
  }
}

.mat-form-field-flex .mat-form-field-infix {
  display: flex !important;
}

.user-profile-thumb {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: block;
  border-radius: 150px;
}

.user-noPhoto {
  width: 100px;
  height: 100px;
}

.font-standard {
  font-size: 18px;
}

.overflow-auto {
  overflow: auto !important;
}


.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.min-h-full {
  min-height: 100% !important;
}

.mat-list-item-content {
  width: 100% !important;
}

.mat-list-base {
  padding: 0 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.no-border-input .mat-form-field-underline {
  display: none;
  font-size: 12px;
}

.no-border-input {
  border-bottom: none;
  box-shadow: none;
  font-size: 12px;
}

.slick-slide {
  margin: 0;
}

.slick-next-custom {
  right: 0;
}

.slick-prev-custom {
  left: 0;
  z-index: 1;
}

.slick-prev-custom, .slick-next-custom {
  position: absolute;
  width: 20%;
  height: 100vh;
  top: 0;
}

.modal-standard {
  background-color: $white;

  .mat-dialog-container {
    border-radius: 0;
  }

  .page-content {
    margin: 0 auto;
    padding: 0;

    .mat-dialog-title {
      background-color: $white;
      padding: 15px;
    }

    .page-header {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .empty {
        width: 64px;

        &.avatar {
          width: 22px;
        }
      }

      h3 {
        font-size: 16px;
        color: $primary;
        margin: 0;
      }
    }

  }
}

.mat-bottom-sheet-container {
  height: 60vh;
  border-radius: 10px 10px 0 0;
}

.icon-send-circle {
  background-color: #17CCF9;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  svg-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cdk-overlay-connected-position-bounding-box {
  right: 35px !important;
}

.circle-lottie {
  top: 50%;
  left: 50%;
  transform: translate(-48%, -54%);
}

.btn-fill {
  font-size: 16px;
  line-height: 18px;
  width: 100%;
  border: solid 1px $primary !important;
  border-radius: 0 !important;
  font-weight: normal;
  color: $primary;
  background-color: $tertiary;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;

  &.w-border {
    border: solid 1px $primary !important;
  }

  &.secondary {
    background-color: #12AEDD;
  }

  @media(max-width: 900px) {
    width: 100%;
  }
}


.bottom-sheet-panel {
  .mat-bottom-sheet-container {
    padding: 0 !important;
    background-color: #EDEDED !important;
    min-height: 90vh !important;
    border: 2px solid $primary;
    //para scrollar só o corpo do bottom sheet e não o header
    overflow: hidden;
  }
}


.bg-purple-backdrop {
  background-color: rgba(34, 28, 70, 0.9) !important;
}

.image-dialog {
  .mat-dialog-container {
    background: transparent !important;
    box-shadow: none !important;
    color: transparent !important;
  }
}

.cdk-overlay-pane.image-dialog {
  border: none !important;
  box-shadow: none !important;
}


.hv-100 {
  height: 100vh;
}

.mat-expansion-panel-header.mat-expanded:focus {
  background: $secondary !important;
}

.mat-expansion-indicator {
  scale: 1.4;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.mat-expanded .mat-expansion-indicator {
  transform: rotate(180deg);
}

.mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color: $primary !important;
}


.ck-content.ck-editor__editable {
  border: none !important;
}

.share-bottom-sheet {
  text-align: center;

  .image-preview {
    margin: 0 auto 20px;
    max-width: 100px;
    max-height: 100px;

    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .share__content__icon {
    margin: 20px 0;
    display: flex;
    align-items: center;

    a {
      display: flex;
      border: 1px solid #221D46;
      padding: 20px 0;
      align-items: center;
      width: 44px;
      height: 44px;
      justify-content: center;
    }

    svg-icon {
      display: flex;
      align-items: center;
      fill: $primary;
    }
  }

  .copy-success {
    color: green;
    margin-top: 10px;
  }
}

.share__content__icon mat-icon {
  font-size: 24px;
  color: $primary
}

.share-modal {
  max-height: 20vh;
}

.ckeditor .ck-editor__editable:focus {
  box-shadow: none;
  outline: none;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  box-shadow: none !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all {
  display: none !important;
}

.circle-header-posts {
  position: relative;
  color: $primary;
  filter: grayscale(100%);

  &.active {
    filter: grayscale(0%);
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 9px;
    text-align: center;
  }

  &.first {
    color: white;
  }

  &.second {
    color: $primary;
  }

  &.tertiary {
    color: $primary;
  }

  &.quaternary {
    color: $primary;
  }
}

.code-input-container {
  display: flex;
  justify-content: space-between;
}

.code-input {
  width: 32px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: none;
  border-bottom: 2px solid $primary;
  color: $primary;
  @media (min-width: 900px) {
    width: 90px;
  }
}

.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: $primary;
}

.panel-expanded::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.padlock {
  width: 18px;
  height: 18px;
  background: $secondary;
  border-radius: 50%;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

//PARCEIROS

.body-partner{
  .login{
    background-color: #00EB5E !important;
  }
}

.winner-container{
  .mat-tab-body{
    overflow: auto;
    max-height: 85vh;
  }
}
