// global

* {
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: $bodyfont;
  margin: 0;
  overflow-x: hidden;
  color: $bodytext;
  font-weight: 400;
  background: #F0F0F0;
}

/*html {
  position: relative;
  min-height: 100%;
  background: $white;
}*/

.main-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  overflow: hidden;
  flex-direction: column;
}

.mat-drawer-container {
  background: $bodycolor;
}

a:hover,
a:focus,
a {
  text-decoration: none;
}

a.link {
  color: $headingtext;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $themecolor;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

hr {
  border: 0px;
  height: 1px;
  background: $border;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: $danger;
  background-color: $light-danger;
  border-radius: 0.25rem;
}

.box {
  border-radius: $radius;
  padding: 10px;
}

.list-inline {
  margin-left: 0px;
  padding: 0px;

  li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.button-row button {
  margin-bottom: 5px;
  margin-right: 5px;
}

html body {
  .position-relative {
    position: relative;
  }

  // list
  .clear {
    clear: both;
  }

  ol li {
    margin: 5px 0;
  }

  // display
  .dl {
    display: inline-block;
  }

  .db {
    display: block;
  }

  //   opacity
  .op-5 {
    opacity: 0.5;
  }

  .op-3 {
    opacity: 0.3;
  }

  .rounded {
    border-radius: $radius;
  }

  //   flex
  .ml-auto {
    margin-left: auto;
  }


  .no-shrink {
    flex-shrink: 0;
  }

  .oh {
    overflow: hidden;
  }

  .hide {
    display: none;
  }

  .img-circle {
    border-radius: 100%;
  }

  .radius {
    border-radius: $radius;
  }

  .no-shadow {
    box-shadow: none;
  }

  //   align vertical
  .vt {
    vertical-align: top;
  }

  .vm {
    vertical-align: middle;
  }

  .vb {
    vertical-align: bottom;
  }

  //   horizontal align
  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  //   border
  .b-0 {
    border: none;
  }

  .b-r {
    border-right: 1px solid $border;
  }

  .b-l {
    border-left: 1px solid $border;
  }

  .b-b {
    border-bottom: 1px solid $border;
  }

  .b-t {
    border-top: 1px solid $border;
  }

  .b-all {
    border: 1px solid $border;
  }
}

.mat-card {
  margin: 0 0 30px 0 !important;
}

.divider-box {
  display: block;
  margin: 20px 0;
}

div.mat-expansion-panel-body {
  padding: 0 0 16px 15px;

  .mat-list-item a span {
    font-size: 13px;
  }
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgb(255 255 255 / 67%) !important;
}

.text-white {
  color: #ffffff !important;
}

.text-red {
  color: #EB0028 !important;
}

.text-blue {
  color: #004691 !important;
}

.text-gray {
  color: #4D4D4D !important;
}

.covegare-th-bg {
  background-color: $primary !important;
  border-top-left-radius: 15px;
  padding: 14px;
  position: relative;
  left: -40px;
  height: 70px;
  @media (max-width: 599px) {
    padding: 5px;
    height: 55px;
    left: 0;
    img {
      transform: scale(0.7);
    }
  }
  display: flex;
  align-items: center;

  &.tim-ultra {
    border-radius: 0 !important;
  }

  &.tim-internet {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 15px !important;
  }
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  font-size: 14px;
  height: 50px;
}

th.mat-header-cell {
  color: #ffffff;
  background-color: #7FA2C8;

  .th-no-padding {
    padding: 0 !important;
  }
}

.mat-title {
  font-weight: bold !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #ffffff !important;
  border: none !important;

}

.mat-dialog-actions {
  @media(max-width: 600px) {
    flex-wrap: nowrap !important;
  }
}

.mat-form-field-label {
  color: #004691 !important;
}

.img-small {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover;
  max-width: 200px;
}

.img-big {
  max-width: 300px;
  width: 300px;
}

.svg-big {
  svg {
    transform: scale(1.5);
  }
}

.w-30 {
  width: 30% !important;
  @media(max-width: 700px) {
    width: 100% !important;
  }
}

.btn-search {
  width: 230px;
  @media(max-width: 700px) {
    width: 100%;
  }
}

::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  border: none !important;
}

.mat-dialog-title {
  margin: 0 !important;
}

.ignore-padding {
  padding: 0 !important;
}

.mat-cell {
  color: #666 !important;
}

//AUTO COMPLETE EMAIL
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 500000s;
}

.btn-outlined {
  font-size: 16px;
  line-height: 18px !important;
  width: 100%;
  font-weight: normal;
  color: #221D46;
  background-color: #FFFFFF;
  border: 1px solid #221D46 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 18px !important;
}

.mat-menu-item {
  color: $primary;

  &:last-of-type {
    border-bottom: none !important;
  }

  height: 67px !important;
  border-bottom: 1px solid $primary !important;
  display: flex !important;
  align-items: center !important;
}

.mat-button.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

.page {
  &-header {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 20px;
    .btn {
      margin: 0 0 0 15px;
    }

    .mat-title {
      margin: 0;
    }

    @media(max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }

  &_bg-secondary {
    &.bg {
      background-color: #EFF3F8;
    }

    @media (max-width: 962px) {
      padding: calc(5rem / 4) !important;
    }

    &.no-padding-media{
      @media (max-width: 962px) {
        padding: 0 !important;
      }
    }
  }

  &-content {
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1280px;
    width: 100%;

    &-space {
      padding-top: 110px;
    }

    &.min-h {
      min-height: 45vh;
    }

    th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
      padding: 0 5px;
    }
  }

  &-inside {
    &-hero {
      width: 100%;
      display: block;
      min-height: 400px;
      background-repeat: repeat-x;
      background-position: top left;
      //background-image: url("/assets/images/page-inside-header-bg.png");

      &.hero-image {
        min-height: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &-gradient {
          &:after {
            content: '';
            display: block;
            width: 100%;
            min-height: inherit;
            background: linear-gradient(0deg, #F0F0F0 0%, rgba(0, 0, 0, 0.5) 60%);
          }
        }
      }

      &.hero-empty {
        min-height: 460px;
      }
    }

    &-container {
      display: block;
      position: relative;
      margin: -250px 0 0 0;

      &.container-image {
        margin: -350px 0 0 0;
      }
    }

    &-header {
      color: $primary;

      &-title {
        font-size: 45px;
        font-weight: bold;
        margin: 0 0 20px 0;

        @media (max-width: 1080px) {
          font-size: 35px;
        }
      }

      &-description {
        font-weight: normal;
        max-width: 384px;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        color: #f0f0f0;
        //margin: 0 0 40px 10px;
      }

      &-row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 780px) {
          flex-direction: column;
        }
      }

      &-col {
        &.col-title {
          min-width: 35%;
          flex-grow: 3;
        }
      }

      &-back {
        top: 0px;
        left: -80px;
        position: absolute;

        @media (max-width: 1280px) {
          top: 0px;
          left: 0px;
          position: relative;
          transform: scale(0.8) translate(0px, -13px);
        }
      }
    }

    &-body {
    }
  }

  &-highlights {
    margin: 10px;
    flex-grow: 1;
    position: relative;

    &-image {
      width: 60%;
      height: 470px;
      object-fit: cover;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      @media(max-width: 1080px) {
        width: 100%;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .card-panel {
      margin: 0;
      width: 100%;
      padding: 0 0;
      display: flex;
      max-width: 100%;
      border-radius: 10px;
      align-items: stretch;
      background-color: $white;
      justify-content: space-between;

      @media(max-width: 1080px) {
        flex-direction: column;
      }

      &-item {
        margin: 0;
        width: 50%;
        align-items: stretch;
        justify-content: space-between;

        @media(max-width: 1080px) {
          width: 100%;
        }
      }
    }
  }

}

.hero {
  display: block;
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  min-height: 150px;
  .carousel {
    position: relative;
  }

  &-bg {
    display: block;
    object-fit: cover;
    padding-top: 75px;
    //object-fit: contain;
    width: 100%;
    mask-image: linear-gradient(to bottom, #F0F0F0 50%, rgba(0, 0.1, 0.1, 0) 100%);

    background: rgb(5, 46, 57);
    background: -moz-linear-gradient(180deg, rgba(5, 46, 57, 1) 0%, rgba(240, 240, 240, 1) 96%);
    background: -webkit-linear-gradient(180deg, rgba(5, 46, 57, 1) 0%, rgba(240, 240, 240, 1) 96%);
    background: linear-gradient(180deg, rgba(5, 46, 57, 1) 0%, rgba(240, 240, 240, 1) 96%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#052e39", endColorstr="#f0f0f0", GradientType=1);
  }


}
