// Utilitários: Espaçamentos, Gaps, Altura e Largura
$spaces: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30);
$sides: (top, right, bottom, left);
$gaps: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45);

$heights: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%
);

$widths: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%
);


@each $space in $spaces {
  .m-#{$space} {
    margin: calc(#{$space}rem / 4) !important;
  }
  .my-#{$space} {
    margin-top: calc(#{$space}rem / 4) !important;
    margin-bottom: calc(#{$space}rem / 4) !important;
  }
  .mx-#{$space} {
    margin-left: calc(#{$space}rem / 4) !important;
    margin-right: calc(#{$space}rem / 4) !important;
  }
  .p-#{$space} {
    padding: calc(#{$space}rem / 4) !important;
  }
  .py-#{$space} {
    padding-top: calc(#{$space}rem / 4) !important;
    padding-bottom: calc(#{$space}rem / 4) !important;
  }
  .px-#{$space} {
    padding-left: calc(#{$space}rem / 4) !important;
    padding-right: calc(#{$space}rem / 4) !important;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: calc(#{$space}rem / 4) !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: calc(#{$space}rem / 4) !important;
    }
  }
}

@each $gap in $gaps {
  .gap-#{$gap} {
    gap: calc(#{$gap}px) !important;
  }

  .gap-x-#{$gap} {
    column-gap: calc(#{$gap}px) !important;
  }

  .gap-y-#{$gap} {
    row-gap: calc(#{$gap}px) !important;
  }

  .flex-grow-#{$gap} {
    flex-grow: $gap !important;
  }
}

@each $key, $value in $heights {
  .h-#{$key} {
    height: $value !important;
  }
}

@each $key, $value in $widths {
  .w-#{$key} {
    width: $value !important;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}


