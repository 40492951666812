/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

$bodyfont: 'TT Travels', sans-serif;
$headingfont: 'TT Travels', sans-serif;

$primary: #221D46;
$secondary: #17CCF9;
$tertiary: #00EB5E;
$warning: #FFDC37;
$danger: #FF7375;

$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$gray-light: #EDEDED;

$themecolor: #1e88e5;

/*bootstrap Color*/
$success: #26c6da;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;

//PALETTE
$mat-primary: (
  50: #e7e8ee,
  100: #c2c5d7,
  200: #9c9fbc,
  300: #777ba1,
  400: #5c608e,
  500: #44457d,
  600: #3e3e75,
  700: #36356a,
  800: #2e2c5d,
  900: #221d46,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$mat-secondary: (
  50: #dff7fd,
  100: #ade9fb,
  200: #72dbfa,
  300: #17ccf9,
  400: #00c0fb,
  500: #00b4fc,
  600: #00a6ee,
  700: #0093db,
  800: #0081c8,
  900: #0061a8,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$mat-warning: (
  50: #ffecef,
  100: #ffcfd5,
  200: #ff9c9d,
  300: #ff7375,
  400: #ff4e50,
  500: #ff3b33,
  600: #ff3134,
  700: #f0242e,
  800: #e31b27,
  900: #d3061a,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);
